var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"promotion-list"}},[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-btn',{staticClass:"me-3",attrs:{"color":"primary","disabled":_vm.hasNotPermission('Permissions.UserCreate')},on:{"click":function($event){return _vm.createDialogShow()}}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('span',[_vm._v("Добавить")])],1)],1),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-text-field',{staticClass:"promotion-list-search me-3",attrs:{"single-line":"","dense":"","outlined":"","hide-details":"","placeholder":"Поиск..."},model:{value:(_vm.filters.searchQuery),callback:function ($$v) {_vm.$set(_vm.filters, "searchQuery", $$v)},expression:"filters.searchQuery"}})],1)],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.users,"server-items-length":_vm.totalCount,"loading":_vm.loading,"page":_vm.filters.pageNumber,"items-per-page":_vm.filters.pageSize,"sort-by":_vm.filters.sortBy,"sort-desc":_vm.filters.sortDesc},on:{"update:page":function($event){return _vm.$set(_vm.filters, "pageNumber", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.filters, "pageSize", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.filters, "pageSize", $event)},"update:sortBy":function($event){return _vm.$set(_vm.filters, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.filters, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.filters, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.filters, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"item.userName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.userName)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.initials",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.initials)+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.role)+" ")]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:((_vm.color(item.isActive)) + "--text"),attrs:{"small":"","color":_vm.color(item.isActive)}},[_vm._v(" "+_vm._s(_vm.getString(item.isActive))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":_vm.hasNotPermission('Permissions.UserActivate'),"href":"javascript:void(0)"},on:{"click":function($event){return _vm.blockPopupShow(item.id, item.userName)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"18","disabled":_vm.hasNotPermission('Permissions.UserBlock')}},[_vm._v(" "+_vm._s(_vm.icons.mdiBlockHelper)+" ")]),_c('span',[_vm._v("Заблокировать")])],1)],1),_c('v-list-item',{attrs:{"disabled":_vm.hasNotPermission('Permissions.UserActivate'),"href":"javascript:void(0)"},on:{"click":function($event){return _vm.activatePopupShow(item.id, item.userName)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"18","disabled":_vm.hasNotPermission('Permissions.UserActivate')}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountReactivate)+" ")]),_c('span',[_vm._v("Активировать")])],1)],1)],1)],1)],1)]}}],null,true)}),_c('delete-dialog',{ref:"blockPopup",attrs:{"label":_vm.deleteLabel},on:{"delete-confirmed":_vm.blockUser}}),_c('delete-dialog',{ref:"activatePopup",attrs:{"label":_vm.activateLabel},on:{"delete-confirmed":_vm.activateUser}}),_c('user-form',{attrs:{"show":_vm.createDialog,"cancellation":_vm.createDialogCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }