<template>
  <v-card id="promotion-list">
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
        <v-btn
          color="primary"
          class="me-3"
          :disabled="hasNotPermission('Permissions.UserCreate')"
          @click="createDialogShow()"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Добавить</span>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="filters.searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Поиск..."
          class="promotion-list-search me-3"
        ></v-text-field>
      </div>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="users"
      :server-items-length="totalCount"
      :loading="loading"
      :page.sync="filters.pageNumber"
      :items-per-page.sync="filters.pageSize"
      :sort-by.sync="filters.sortBy"
      :sort-desc.sync="filters.sortDesc"
      class="text-no-wrap"
    >
      <template #[`item.userName`]="{item}">
        {{ item.userName }}
      </template>
      <template #[`item.email`]="{item}">
        {{ item.email }}
      </template>
      <template #[`item.initials`]="{item}">
        {{ item.initials }}
      </template>
      <template #[`item.role`]="{item}">
        {{ item.role }}
      </template>
      <template #[`item.isActive`]="{item}">
        <v-chip
          small
          :color="color(item.isActive)"
          :class="`${color(item.isActive)}--text`"
        >
          {{ getString(item.isActive) }}
        </v-chip>
      </template>
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :disabled="hasNotPermission('Permissions.UserActivate')"
                @click="blockPopupShow(item.id, item.userName)"
                href="javascript:void(0)"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    :disabled="hasNotPermission('Permissions.UserBlock')"
                    class="me-2"
                  >
                    {{ icons.mdiBlockHelper }}
                  </v-icon>
                  <span>Заблокировать</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                :disabled="hasNotPermission('Permissions.UserActivate')"
                href="javascript:void(0)"
                @click="activatePopupShow(item.id, item.userName)"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    :disabled="hasNotPermission('Permissions.UserActivate')"
                    class="me-2"
                  >
                    {{ icons.mdiAccountReactivate }}
                  </v-icon>
                  <span>Активировать</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <delete-dialog @delete-confirmed="blockUser" :label="deleteLabel" ref="blockPopup" />
    <delete-dialog @delete-confirmed="activateUser" :label="activateLabel" ref="activatePopup" />
    <user-form :show="createDialog" :cancellation="createDialogCancel"></user-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import DeleteDialog from '@/components/delete-dialog'
import UserForm from './user-form'
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiBlockHelper,
  mdiAccountReactivate
} from '@mdi/js'
import { hasPermission } from '@/helpers'

export default {
  components: {DeleteDialog, UserForm},
  name: 'UserList',
  data: () => ({
    createDialog: false,
    icons: {
      mdiTrendingUp,
      mdiPlus,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiEyeOutline,
      mdiBlockHelper,
      mdiAccountReactivate
    },
    deleteLabel: '',
    activateLabel: '',
    deleteMethod: null,
  }),
  computed: {
    ...mapFormHandlers('user', [
      'filters.pageNumber',
      'filters.pageSize',
      'filters.searchQuery',
      'filters.sortBy',
      'filters.sortDesc'
    ]),
    ...mapState('user', { users: 'list', totalCount: 'totalCount', loading: 'loading', searchQuery: 'searchQuery' }),
    headers() {
      return [
        { text: 'Логин', value: 'userName' },
        { text: 'Почта', value: 'email' },
        { text: 'Инициалы', value: 'initials' },
        { text: 'Роль', value: 'role' },
        { text: 'Статус', value: 'isActive' },
        {
          text: 'Действия',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  async created() {
    document.title = 'Пользователи';
    await this.fetchUsers();
  },
  methods: {
    ...mapActions('user', { fetchUsers: 'fetchList', blockUser: 'block', activateUser: 'activate' }),
    blockPopupShow(id, name) {
      this.deleteLabel = 'Пользователь ' + name + ' будет заблокирован.'
      this.$refs.blockPopup.open(id)
    },
    createDialogShow() {
      this.createDialog = true
    },
    hasNotPermission(val){
      return !hasPermission(val);
    },
    createDialogCancel(){
      this.createDialog = false
    },
    activatePopupShow(id, name){
      this.activateLabel = 'Пользователь ' + name + ' будет активирован.'
      this.$refs.activatePopup.open(id)
    },
    getString(isActive){
      return isActive ? 'Активный' : 'Заблокирован'
    },
    color(isActive){
      return isActive ? 'success' : 'error';
    }
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
