<template>
  <v-dialog
    v-model="show"
    max-width="900px"
    @click:outside="cancel"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Добавление пользователя
      </v-card-title>

      <v-card-text class="mt-5">
        <v-form class="multi-col-validation" ref="form">
          <v-row>
            <v-col
              cols="8"
              sm="4"
            >
              <v-text-field
                v-model="lastName"
                outlined
                dense
                label="Фамилия"
              ></v-text-field>
            </v-col>
            <v-col
              cols="8"
              sm="4"
            >
              <v-text-field
                v-model="firstName"
                outlined
                dense
                label="Имя"
              ></v-text-field>
            </v-col>
            <v-col
              cols="8"
              sm="4"
            >
              <v-text-field
                v-model="patronymic"
                outlined
                dense
                label="Отчество"
              ></v-text-field>
            </v-col>
            <v-divider class="mt-4"></v-divider>
            <v-col
              cols="8"
              sm="4"
            >
              <v-text-field
                v-model="userName"
                :rules="[rules.required]"
                outlined
                :error="hasErrorsForProperty(errors.errors, 'userName')"
                :error-messages="errorMessages(errors.errors, 'userName')"
                dense
                label="Логин"
              ></v-text-field>
            </v-col>

            <v-col
              cols="8"
              sm="4"
            >
              <v-text-field
                v-model="email"
                :rules="[rules.emailValidator]"
                outlined
                dense
                label="Почта"
              ></v-text-field>
            </v-col>
            <v-col
              cols="8"
              sm="4"
            >
              <v-select
                v-model="role"
                :items="rolesDropdown"
                :rules="[rules.required]"
                outlined
                dense
                label="Роль"
              ></v-select>
            </v-col>
            <v-divider class="mt-4"></v-divider>
            <v-col
              cols="8"
              sm="4"
            >
              <v-text-field
                v-model="password"
                :rules="[rules.required, rules.passwordValidator]"
                type="password"
                outlined
                dense
                :type="isPasswordVisible ? 'text' : 'password'"
                :append-icon="isPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                @click:append="isPasswordVisible = !isPasswordVisible"
                label="Пароль"
              ></v-text-field>
            </v-col>
            <v-col
              cols="8"
              sm="4"
            >
              <v-text-field
                v-model="confirmPassword"
                :rules="[rules.required, rules.confirmedValidator(confirmPassword, password)]"
                type="password"
                outlined
                :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                @click:append="isCPasswordVisible = !isCPasswordVisible"
                dense
                label="Подтверждение пароля"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn
                @click="saveData"
                color="primary"
                class="me-3 mt-4"
              >
                Сохранить
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                @click="cancel"
              >
                Отмена
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCheck, mdiClose, mdiLockOutline, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions, mapGetters } from 'vuex'
import { rules } from '@/helpers/validation'
import { errorMessages, hasErrorsForProperty } from '@/helpers'

export default {
  name: 'ApplicationDescriptionForm',
  props: ['show', 'cancellation'],
  data: () => ({
    icons: {
      mdiCheck,
      mdiClose,
      mdiLockOutline,
      mdiEyeOutline,
      mdiEyeOffOutline,
    },
    rules,
    hasErrorsForProperty,
    errorMessages,
    isCPasswordVisible: false,
    isPasswordVisible: false,
  }),
  computed: {
    ...mapFormHandlers('user/form', [
      'userName',
      'email',
      'firstName',
      'role',
      'lastName',
      'patronymic',
      'password',
      'confirmPassword',
      'errors',
    ]),
    ...mapGetters('dictionary', { rolesDropdown: 'rolesDropdown' }),
  },
  async mounted() {
    await this.fetchRoles()
  },
  watch: {
    show: function(newValue) {
      if (newValue && this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
  },
  methods: {
    cancel() {
      this.resetForm()
      this.cancellation()
    },
    ...mapActions('user/form', ['resetForm', 'create']),
    ...mapActions('dictionary', { fetchRoles: 'fetchRoles' }),
    async saveData() {
      if (this.$refs.form.validate()) {
        await this.create()
        this.resetForm()
        this.cancellation()
      }
    },
  },
}
</script>

<style scoped>

</style>
